/* For full HD display */
.services-main-container {
  position: relative; /* Make the container relative */
  width: 100%;
  height: 550px;
  padding-top: 120px;
}
.services-main-container-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.services-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 550px;
  padding-top: 120px;
  background-color: rgba(0, 0, 0, 0.5);
}
.services-text-content {
  height: 420px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: center;
}
.services-title {
  font-size: clamp(2rem, 4vw, 4rem);
  color: #fff;
  font-weight: bolder;
}
.services-sub-title {
  font-size: clamp(1.25rem, 4vw, 2rem);
  color: #fff;
  font-weight: bold;
}
.all-services-list {
  margin: 80px 0;
}
.all-services-list img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.services-header {
  font-size: clamp(3rem, 4vw, 5rem);
  font-weight: bold;
  line-height: normal;
  color: #0000000f;
  position: absolute;
  text-align: center;
  left: 0;
  width: 100%;
}
.services-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  padding: 10px 0;
}
.services-top-left {
  height: 286px;
  flex: 1;
}
.services-top-right {
  height: 286px;
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.services-topr-right-title {
  font-weight: bold;
  font-size: clamp(1.5rem, 4vw, 2.5rem);
}
.services-topr-right-sub-title {
  font-weight: 500;
  font-size: clamp(1rem, 4vw, 2rem);
}
.services-bottom {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-between;
  padding: 10px 0;
}
.services-bottom > div {
  flex: 1;
  height: 286px;
  width: auto;
}

/* For smaller screens such as tablets */
@media (max-width: 1024px) {
  .services-main-container,
  .services-overlay {
    height: 450px;
  }
  .services-text-content {
    height: 300px;
  }
}

/* For mobile phones */
@media (max-width: 768px) {
  .services-main-container,
  .services-overlay {
    height: 350px;
  }
  .services-text-content {
    height: 200px;
  }
  .all-services-list {
    margin: 50px 0;
  }
  .services-top {
    flex-direction: column-reverse;
  }
  .services-bottom {
    flex-direction: column;
  }
}

.header-container {
  width: 100%;
  height: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  position: absolute;
  z-index: 1;
}
.header-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  height: 80px;
}
.logo img {
  height: 200px;
  width: auto;
}

.list-items ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 2rem;
  padding: 0 2rem;
  align-items: center;
}

.list-items ul li {
  color: #fff;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
}
.list-items ul li:hover {
  color: #f28f1a; /* Color change on hover */
}
.contact-button {
  font-size: clamp(0.6em, 4vw, 1em);
  border-radius: 20px;
  background: transparent;
  padding: 0.5rem 2rem;
  font-weight: 600;
  color: #fff;
  border: 1px solid #fff;
  cursor: pointer;
}
.contact-button:hover {
  color: #f28f1a;
}
.menu-icon {
  display: none;
}
.list-items ul.menu-open {
  display: flex;
  flex-direction: column;
  background-color: grey;
  box-shadow: 0px 4px 4px 0px #00000040;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100dvw;
  z-index: 2;
}

.list-items ul.menu-open li {
  padding: 1rem;
  color: #fff;
  cursor: pointer;
  margin-left: -15px;
}

@media (max-width: 768px) {
  .header-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    box-shadow: 0px 4px 4px 0px #00000040;
    background-color: grey;
    height: auto;
  }
  .menu-icon {
    display: inline;
  }
  .list-items ul {
    display: none;
  }
  .logo img {
    margin-left: -30px;
    height: 150px;
  }
}

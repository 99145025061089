.footer-container {
  background-color: #061936;
  padding: 2rem 0;
}
.footer-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  text-align: left;
}
.footer-content > div {
  flex: 1;
  color: #fff;
}
.footer-title {
  font-weight: bold;
  font-size: clamp(1em, 4vw, 2em);
}
.footer-subtitle {
  font-weight: 700;
  font-size: clamp(0.6em, 4vw, 1.3em);
}
.footer-address {
  font-weight: 400;
  font-size: clamp(0.6em, 4vw, 1.3em);
  line-height: normal;
}
.footer-email {
  font-weight: 400;
  font-size: clamp(0.6em, 4vw, 1.3em);
}
.footer-phone {
  font-weight: 700;
  font-size: clamp(0.6em, 4vw, 1.3em);
}
.footer-address:hover,
.footer-email:hover,
.footer-phone:hover {
  color: #f28f1a;
  cursor: pointer;
}
.footer-content > div:nth-child(2) div {
  margin-top: 5px;
}
.footer-content > div:nth-child(2) {
  padding-left: 3rem;
}

.footer-content > div:nth-child(3) {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.footer-social-icons {
  display: flex;
  gap: 1.5rem;
  padding: 2rem 0;
}
.footer-whatsapp-enquiry {
  background: #4bc95a;
  border-radius: 46px;
  display: flex;
  align-items: center;
  padding: 0.5rem 2rem;
  gap: 10px;
  color: #fff;
  font-weight: 700;
  font-size: clamp(0.6em, 4vw, 1.3em);
  cursor: pointer;
  text-decoration: none;
  position: fixed;
  bottom: 10px;
  right: 10px;
}
@media only screen and (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    text-align: left;
  }
  .footer-content > div:nth-child(2) {
    padding-left: 0;
  }
}
